<template>
  <main class="page">
    <header class="page__header p-2 px-3 d-flex flex-row align-items-center">
      <template v-if="project">
        <router-link :to="{ name: 'home' }" class="mr-3 d-inline-block" style="height: 100%; width: auto; border-radius: 25px">
          <b-img :src="project.thumbSrc" class="d-block h-100 border-1" />
        </router-link>
        <router-link :to="{ name: 'home' }" class="h2 m-0 d-inline-block text-dark text-nowrap overflow-auto">{{ project.title }}</router-link>
      </template>
    </header>
    <div ref="container" class="page__content position-relative">
      <div v-if="noPermissions" class="p-5">
        <p>Errore, impossibile ottenere i permessi per utilizzare la fotocamera del dispositivo.</p>
        <div v-if="appStoreLinks">
          <p>Scarica l'app per utilizzare le funzioni di Eureka Piclink.</p>

          <div class="d-flex">
            <a v-if="appleStoreLink" class="flex-grow-1" :href="appleStoreLink" target="_blank">
              <img :src="require('@/assets/images/store/appstore.svg')" alt="">
            </a>
            <div v-if="appStoreLinks && googleStoreLink" style="width: 50px"></div>
            <a v-if="googleStoreLink" class="flex-grow-1" :href="googleStoreLink" target="_blank">
              <img :src="require('@/assets/images/store/googleplay.svg')" alt="">
            </a>
          </div>
        </div>
      </div>
      <div v-else class="webcam overflow-hidden">
        <video ref="webcam" class="webcam__video" :style="webcamVideoStyle" autoplay playsinline></video>
        <canvas ref="webcamCanvas" class="webcam__canvas d-none"></canvas>

        <div v-if="elaborating" class="webcam__elaborating text-center p-4">
          <b-spinner></b-spinner>
          <p class="mb-0 mt-3">Elaborazione in corso</p>
        </div>
        <div v-if="!elaborating" class="webcam__overlay">
          <div class="webcam__overlay__top" :style="overlayTopStyle"></div>
          <div class="webcam__overlay__center" :style="overlayCenterStyle">
            <div class="webcam__overlay__center__left" :style="overlayLeftStyle"></div>
            <div class="webcam__overlay__center__center" :style="overlayViewfinderStyle"></div>
            <div class="webcam__overlay__center__right"></div>
          </div>
          <div class="webcam__overlay__bottom">
            <div class="webcam__buttons">
              <div class="btn-small" @click.prevent="toggleCameraOrientation">
                <i class="piclink-icon" :class="{ 'icon-horizontal-photo': cameraOrientation, 'icon-vertical-photo': !cameraOrientation }"></i>
              </div>
              <div class="btn-big" @click.prevent="fire">
                <img :src="require('@/assets/images/pages/fire-button.svg')" alt="" class="img-fluid">
              </div>
              <div class="btn-small hidden"></div>
            </div>
          </div>
          <div class="webcam__overlay__angles webcam__overlay__angles__tl" :style="overlayViewfinderAnglesTopLeftStyle"></div>
          <div class="webcam__overlay__angles webcam__overlay__angles__tr" :style="overlayViewfinderAnglesTopRightStyle"></div>
          <div class="webcam__overlay__angles webcam__overlay__angles__br" :style="overlayViewfinderAnglesBottomRightStyle"></div>
          <div class="webcam__overlay__angles webcam__overlay__angles__bl" :style="overlayViewfinderAnglesBottomLeftStyle"></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import Webcam from 'webcam-easy';
import Swal from 'sweetalert2';

const ratio = 4 / 3;

export default {
  data () {
    return {
      noPermissions: false,

      webcam: null,
      project: null,

      canvasWidth: null,
      canvasHeight: null,
      webcamWidth: null,
      webcamHeight: null,

      cameraOrientation: false,

      elaborating: false,

      feedback: null,
    };
  },
  computed: {
    appStoreLinks () {
      return this.appleStoreLink || this.googleStoreLink;
    },
    appleStoreLink () {
      if (!this.$config.iTunesAppId) {
        return null;
      }

      return `https://apps.apple.com/tt/app/id${this.$config.iTunesAppId}`;
    },
    googleStoreLink () {
      if (!this.$config.androidPackageId) {
        return null;
      }

      return `https://play.google.com/store/apps/details?id=${this.$config.androidPackageId}`;
    },

    webcamRatio () {
      if (!this.webcamWidth) {
        return null;
      }

      return this.webcamWidth / this.webcamHeight;
    },
    canvasRatio () {
      if (!this.canvasWidth) {
        return null;
      }

      return this.canvasWidth / this.canvasHeight;
    },
    marginLeft () {
      if (this.canvasRatio > this.webcamRatio) {
        return 0;
      }

      const webcamComputedWidth = this.webcamRatio * this.canvasHeight;

      return parseInt((webcamComputedWidth - this.canvasWidth) / 2);
    },
    marginTop () {
      if (this.canvasRatio <= this.webcamRatio) {
        return 0;
      }

      const webcamComputedHeight = this.canvasWidth / this.webcamRatio;

      return parseInt((webcamComputedHeight - this.canvasHeight) / 2);
    },
    webcamVideoStyle () {
      if (this.canvasRatio <= this.webcamRatio) {
        return {
          'margin-left': (-this.marginLeft) + 'px',
          width: 'auto',
          height: '100%',
        };
      }

      return {
        'margin-top': (-this.marginTop) + 'px',
        width: '100%',
        height: 'auto',
      };
    },

    viewFinderProps () {
      const controlBoxHeight = 70;
      const viewFinderAnglesMT = 30;
      const viewFinderAnglesMB = 20;
      const viewFinderAnglesMX = 20;
      const canvasMY = 15;
      const canvasMX = 15;
      const viewFinderAnglesHeight = 75;
      const viewFinderAnglesWidth = 75;

      // Calculated
      const screenWidth = this.canvasWidth;
      const screenHeight = this.canvasHeight;
      const availableHeight = screenHeight - controlBoxHeight - 2 * canvasMY - viewFinderAnglesMT - viewFinderAnglesMB;
      const availableWidth = screenWidth - 2 * canvasMX - 2 * viewFinderAnglesMX;

      let width = 0;
      let height = 0;

      if (this.cameraOrientation) {
        // Landscape
        // Prova ad occupare tutta la larghezza
        width = availableWidth;
        height = width / ratio;

        if (height > availableHeight) {
          height = availableHeight;
          width = height * ratio;
        }
      } else {
        // Portrait
        // Prova ad occupare tutta l'altezza
        height = availableHeight;
        width = height / ratio;

        if (width > availableWidth) {
          width = availableWidth;
          height = width * ratio;
        }
      }

      const top = (screenHeight - height - controlBoxHeight) / 2;
      const left = (screenWidth - width) / 2;

      return {
        screen: {
          height: screenHeight,
          width: screenWidth,
        },
        viewFinderAngles: {
          t: {
            y: top - canvasMY,
            h: viewFinderAnglesHeight,
          },
          b: {
            y: top + height - viewFinderAnglesHeight + canvasMY,
            h: viewFinderAnglesHeight,
          },
          l: {
            x: left - canvasMX,
            w: viewFinderAnglesWidth,
          },
          r: {
            x: left + width - viewFinderAnglesWidth + canvasMX,
            w: viewFinderAnglesWidth,
          },
        },
        canvas: {
          x: left,
          y: top,
          h: height,
          w: width,
        },
      };
    },
    overlayTopStyle () {
      return {
        height: this.viewFinderProps.canvas.y + 'px',
      };
    },
    overlayCenterStyle () {
      return {
        height: this.viewFinderProps.canvas.h + 'px',
      };
    },
    overlayLeftStyle () {
      return {
        width: this.viewFinderProps.canvas.x + 'px',
      };
    },
    overlayViewfinderStyle () {
      return {
        width: this.viewFinderProps.canvas.w + 'px',
      };
    },
    overlayViewfinderAnglesTopLeftStyle () {
      return {
        top: this.viewFinderProps.viewFinderAngles.t.y + 'px',
        left: this.viewFinderProps.viewFinderAngles.l.x + 'px',
        height: this.viewFinderProps.viewFinderAngles.t.h + 'px',
        width: this.viewFinderProps.viewFinderAngles.l.w + 'px',
      };
    },
    overlayViewfinderAnglesTopRightStyle () {
      return {
        top: this.viewFinderProps.viewFinderAngles.t.y + 'px',
        left: this.viewFinderProps.viewFinderAngles.r.x + 'px',
        height: this.viewFinderProps.viewFinderAngles.t.h + 'px',
        width: this.viewFinderProps.viewFinderAngles.r.w + 'px',
      };
    },
    overlayViewfinderAnglesBottomRightStyle () {
      return {
        top: this.viewFinderProps.viewFinderAngles.b.y + 'px',
        left: this.viewFinderProps.viewFinderAngles.r.x + 'px',
        height: this.viewFinderProps.viewFinderAngles.b.h + 'px',
        width: this.viewFinderProps.viewFinderAngles.r.w + 'px',
      };
    },
    overlayViewfinderAnglesBottomLeftStyle () {
      return {
        top: this.viewFinderProps.viewFinderAngles.b.y + 'px',
        left: this.viewFinderProps.viewFinderAngles.l.x + 'px',
        height: this.viewFinderProps.viewFinderAngles.b.h + 'px',
        width: this.viewFinderProps.viewFinderAngles.l.w + 'px',
      };
    },
  },
  methods: {
    computeCanvasSize () {
      this.canvasWidth = this.$refs.container.clientWidth;
      this.canvasHeight = this.$refs.container.clientHeight;
    },
    toggleCameraOrientation () {
      this.cameraOrientation = !this.cameraOrientation;
    },
    fire () {
      this.elaborating = true;
      const image = this.webcam.snap('image/jpeg');

      const img = new Image();
      img.src = image;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = this.viewFinderProps.canvas.w;
        canvas.height = this.viewFinderProps.canvas.h;

        let sx = this.viewFinderProps.canvas.x - this.marginLeft;
        if (sx < 0) {
          sx = this.viewFinderProps.canvas.x;
        }

        let sy = this.viewFinderProps.canvas.y - this.marginTop;
        if (sy < 0) {
          sy = this.viewFinderProps.canvas.y;
        }

        canvas.getContext('2d').drawImage(img, sx, sy, this.viewFinderProps.canvas.w, this.viewFinderProps.canvas.h, 0, 0, this.viewFinderProps.canvas.w, this.viewFinderProps.canvas.h);

        const data = canvas.toDataURL('image/jpeg');
        this.$http.post(`${this.project.hostname}/search/image`, {
          user: this.$storage.get('username') || null,
          base64: data,
          project: this.project.id,
          rotationDegree: 0,
        }, {
          responseType: 'json',
        })
          .then(res => {
            if (!res.data.image_id) {
              throw new Error('Match not found');
            }

            if (!res.data?.likelihood || res.data.likelihood < 0.1) {
              throw new Error('Not found');
            }

            const resource = res?.data || {};

            if (resource && resource?.sendUser) {
              resource.url = this.addParameterToQueryString(resource?.url || '', '___mz_u', this.$storage.get('username') || null);
            }

            const url = resource?.url;
            if (!url) {
              throw new Error('Url not found');
            }

            if (!window.open(url, '_blank')) {
              Swal.fire({
                icon: 'success',
                title: 'Marker riconosciuto',
                footer: '<div class="container"><div class="row justify-content-center"><div class="col-auto"><a class="btn btn-success success-button" target="_blank" href="' + url + '">Visualizza la risorsa</a></div></div></div>',
                didOpen: (dom) => {
                  const button = dom.querySelector('.success-button');
                  button.addEventListener('click', () => {
                    Swal.close();
                  });
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
              });
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Marker non riconosciuto, inquadrato male o da troppo lontano',
              text: 'Marker non riconosciuto, inquadrato male o da troppo lontano',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          })
          .finally(() => {
            this.elaborating = false;
          })
        ;
      };
    },
    addParameterToQueryString (uri, key, value) {
      const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
      const separator = uri.indexOf('?') !== -1 ? '&' : '?';
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
      }

      return uri + separator + key + '=' + value;
    },
    computeWebcamSize () {
      this.webcamWidth = this.$refs?.webcam?.videoWidth;
      this.webcamHeight = this.$refs?.webcam?.videoHeight;
    },
  },
  mounted () {
    const project = (this.$storage.get('projects') || []).find(p => p.id === this.$route.params.projectId);
    if (!project) {
      this.$router.replace({ name: 'home' });
      return;
    }
    this.project = project;

    window.addEventListener('resize', this.computeCanvasSize);
    this.computeCanvasSize();
    this.$nextTick(() => {
      this.computeCanvasSize();
      this.webcam = new Webcam(this.$refs.webcam, 'environment', this.$refs.webcamCanvas);
      this.webcam.start()
        .then(() => {
          this.$refs.webcam.addEventListener('resize', this.computeWebcamSize);
          this.computeWebcamSize();

          this.computeCanvasSize();
        })
        .catch(() => {
          this.noPermissions = true;
        })
      ;
    });
  },
  destroyed () {
    this.webcam.stop();
    window.removeEventListener('resize', this.computeCanvasSize);
  },
};

</script>

<style lang="scss">

$overlay-color: rgba(255, 255, 255, .45);
$scanViewfinderExternalBorder: #3D3935;
$scanViewfinderBorder: #FFD100;

.webcam {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__video {
    display: block;
    position: relative;
    z-index: 999;
    transform: none !important;
  }

  &__elaborating {
    width: 50%;
    min-width: 150px;
    max-width: 350px;
    border-radius: 25px;
    background-color: $scanLoadingBackgroundColor;
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $scanLoadingForegroundColor;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: column;

    &__top {
      background-color: $overlay-color;
    }

    &__center {
      display: flex;
      flex-direction: row;

      &__left {
        background-color: $overlay-color;
      }

      &__center {
        border: 3px solid $scanViewfinderBorder;
      }

      &__right {
        background-color: $overlay-color;
        flex-grow: 1;
      }
    }

    &__bottom {
      background-color: $overlay-color;
      flex-grow: 1;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    &__angles {
      position: absolute;
      border: solid 0 $scanViewfinderExternalBorder;

      &__tl {
        border-top-width: 2px;
        border-left-width: 2px;
      }

      &__tr {
        border-top-width: 2px;
        border-right-width: 2px;
      }

      &__br {
        border-bottom-width: 2px;
        border-right-width: 2px;
      }

      &__bl {
        border-bottom-width: 2px;
        border-left-width: 2px;
      }
    }
  }

  &__buttons {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .btn-small {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background-color: #EDEDED;

      &.hidden {
        background-color: transparent !important;
      }
    }

    .btn-big {
      width: 60px;
      height: 60px;
    }
  }
}

</style>
